<template>
  <div class="d-flex flex-column align-center pt-16">
    <!-- Show the animation here -->
    <div style="max-width: 200px">
      <lottie-animation
        loop
        file="94900-error.json"
      />
    </div>

    <!-- Show a text message in between -->
    <p class="px-4 text-center" style="max-width: 560px;">
      {{ $route.query.message || "An error occurred!" }}
    </p>

    <!-- Show a button to homepage -->
    <div class="d-flex justify-center">
      <v-btn
        depressed
        color="primary"
        :to="{ name: 'Dashboard' }"
      >
        <v-icon left>
          keyboard_return
        </v-icon>

        Back to Dashboard
      </v-btn>
    </div>
  </div>
</template>

<script>
// Import children components
const LottieAnimation = () => import(/* webpackChunkName: "lottie-animation" */ "@/components/common/LottieAnimation.vue")

// Export the SFC
export default {
  // Name of the component
  name: "Error",

  // Register the children components
  components: {
    LottieAnimation
  }
}
</script>
